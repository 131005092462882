
type Data = {
    [key: string]: unknown;
};

type Callback = (event: Data & {
    type: string;
    target: unknown;
}) => void;

export default class EventEmitter {
    private _events: { [type: string]: Callback[] };
 
    constructor() {
        this._events = {};
    }

    on(type: string, callback: Callback): void {
        let listeners = this._events[type];
        if (!listeners) {
            listeners = [];
            this._events[type] = listeners;
        }
        for (let i = 0; i < listeners.length; ++i) {
            if (listeners[i] === callback) return;
        }
        listeners.push(callback);
    }

    off(type: string, callback: Callback): void {
        const listeners = this._events[type];
        if (!listeners) return;
        for (let i = listeners.length; i >= 0; --i) {
            if (listeners[i] === callback) {
                listeners.splice(i, 1);
                return;
            }
        }
    }

    protected fire(type: string, data: Data = {}): void {
        const listeners: Callback[] = this._events[type];
        if (!listeners) return;
        listeners.forEach((callback) => {
            callback({
                ...data,
                type: type,
                target: this
            });
        });
    }
}
