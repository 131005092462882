/* eslint-disable max-lines */
// TODO: break up the file and use react.
require("../../common/js/jquery.ui.touch-punch.min.js");

import Evented from "./../../common/js/Evented";

class WrldIndoorControl extends Evented {

  constructor(id, map) {
    super();

    this._map = map;
    this._mountNode = typeof id === "string" ? document.getElementById(id) : id;
    this._floorSliderExists = false;
    this._showAllFloors = false;
    this._maxFloorsToShow = 0;
    this._floorCount = 0;
    this._baseSliderFloorIndex = 0;
    this._floorIndex = 0;
    this._floorSliderScrollSpeed = 0;
    this._floorSliderScrollDelta = 0;
    this._floorSliderHeight = 0;

    this._indoorControl = null;
    this._exitButton = null;
    this._floorSliderContainer = null;
    this._tracker = null;
    this._barsContainer = null;
    this._thumbContainer = null;
    this._thumb = null;
    this._thumbTooltipHook = null;
    this._numbersContainer = null;
    this._upIndicatorArrow = null;
    this._downIndicatorArrow = null;

    this._onIndoorMapEntered = this._onIndoorMapEntered.bind(this);
    this._onIndoorMapExit = this._onIndoorMapExit.bind(this);
    this._onIndoorFloorChange = this._onIndoorFloorChange.bind(this);
    this._updateSliderHeight = this._updateSliderHeight.bind(this);
    this._updateSlider = this._updateSlider.bind(this);
    this._onThumbMouseDown = this._onThumbMouseDown.bind(this);
    this._onThumbMouseUp = this._onThumbMouseUp.bind(this);
    this._onThumbDrag = this._onThumbDrag.bind(this);
    this._onThumbDragEnd = this._onThumbDragEnd.bind(this);
    this._onExitButtonClicked = this._onExitButtonClicked.bind(this);

    this._map.indoors.on("indoormapenter", this._onIndoorMapEntered);
    this._map.indoors.on("indoormapexit", this._onIndoorMapExit);
  }

  getThumbCenterPosition() {
    if (!this._floorSliderExists) { return null; }
    const thumbTop = this._thumbContainer.style.top.length > 0 ? parseInt(this._thumbContainer.style.top) : $(this._tracker).height();
    return {
      x: $(this._floorSliderContainer).width() * 0.5,
      y: $(this._exitButton).height() + parseInt($(this._floorSliderContainer).css("marginTop")) + thumbTop
    };
  }

  hasFloorSlider() {
    return this._floorSliderExists;
  }

  _onExitButtonClicked() {
    this.fire("beginindoormapexit");
    this._map.indoors.off("indoormapfloorchange", this._onIndoorFloorChange);
    $(window).off("resize", this._updateSliderHeight);
    this._map.indoors.exit();
  }

  _setThumbPosition() {
    const numFloorsShowing = this._showAllFloors ? this._floorCount : this._maxFloorsToShow;
    const percent = (this._floorIndex - this._baseSliderFloorIndex) / (numFloorsShowing - 1);
    const top = (1 - percent) * this._floorSliderHeight;
    this._thumbContainer.style.top = top + "px";
  }

  _onThumbMouseDown(event) {
    if (event.button !== 0) {
      // Must remove this class incase the primary mouse button was released off the thumb.
      this._thumb.classList.remove("primary-button-down");
      return;
    }
    this._thumb.classList.add("primary-button-down");
    this._map.indoors.expand();
    this._tracker.style.opacity = 1.0;

    if (this._floorSliderExists && !this._showAllFloors) {
      this._map.on("update", this._updateSlider);
    }
  }

  _onThumbMouseUp(event) {
    if (event.button !== 0) return;
    this._thumb.classList.remove("primary-button-down");
    this._endThumbInteraction();
  }

  // the elevator UI doesn't have enough room to display the full string
  _getTruncatedFloorNumberText(originalText) {
    return originalText.substring(0, 2);
  }

  _onThumbDrag(event, ui) {
    const percent = 1 - (ui.position.top / this._floorSliderHeight);
    const numFloorsShowing = this._showAllFloors ? this._floorCount : this._maxFloorsToShow;
    const tValue = (percent * (numFloorsShowing - 1) + this._baseSliderFloorIndex) / this._floorCount;
    this._floorIndex = Math.round(percent * (numFloorsShowing - 1)) + this._baseSliderFloorIndex;
    this._thumb.childNodes[0].innerHTML = this._getTruncatedFloorNumberText(this._map.indoors.getActiveIndoorMap().getFloors()[this._floorIndex].getFloorShortName());
    this._map.indoors.setFloorInterpolation(tValue);
    this._updateSliderNumbers();

    if (!this._showAllFloors) {
      this._floorSliderScrollSpeed = Math.max(percent - 0.925, Math.min(percent - 0.075, 0)) * 4;
    }
  }

  _onThumbDragEnd(event, ui) {
    this._setThumbPosition();
    this._map.indoors.setFloor(this._floorIndex);
    this._endThumbInteraction();
  }

  _endThumbInteraction() {
    this._map.indoors.collapse();
    this._tracker.style.opacity = 0.5;

    if (this._floorSliderExists && !this._showAllFloors) {
      this._map.off("update", this._updateSlider);
      this._floorSliderScrollSpeed = 0;
      this._floorSliderScrollDelta = 0;
    }
  }

  _onIndoorMapEntered() {
    this._floorCount = this._map.indoors.getActiveIndoorMap().getFloorCount();
    this._baseSliderFloorIndex = 0;
    this._floorIndex = this._map.indoors.getFloor().getFloorIndex();
    this._floorSliderScrollSpeed = 0;
    this._floorSliderScrollDelta = 0;

    const hasFloors = this._floorCount > 1;
    this._createView(hasFloors);
  }

  _onIndoorMapExit() {
    this._destroyView();
  }

  _updateBaseSliderFloorIndex() {
    if (!this._showAllFloors) {
      if (this._floorIndex <= this._baseSliderFloorIndex) {
        this._baseSliderFloorIndex = this._maxFloorsToShow === 2 ? this._floorIndex : Math.max(this._floorIndex - 1, 0);
      }
      else if (this._floorIndex >= this._baseSliderFloorIndex + this._maxFloorsToShow - 1) {
        this._baseSliderFloorIndex = Math.min(this._floorIndex - this._maxFloorsToShow + 2, this._floorCount - this._maxFloorsToShow);
      }
      else if (this._baseSliderFloorIndex + this._maxFloorsToShow >= this._floorCount) {
        this._baseSliderFloorIndex = this._floorCount - this._maxFloorsToShow;
      }
    }
    else {
      this._baseSliderFloorIndex = 0;
    }
  }

  _onIndoorFloorChange(event) {
    if (this._floorSliderExists) {
      this._floorIndex = event.floor.getFloorIndex();
      this._thumb.childNodes[0].innerHTML = this._getTruncatedFloorNumberText(event.floor.getFloorShortName());
      this._updateBaseSliderFloorIndex();
      this._updateSliderNumbers();
      this._setThumbPosition();
    }
  }

  _createView(hasFloors) {
    if(this._indoorControl && this._indoorControl.parentNode) {
      this._destroyView();
    }
    this._indoorControl = document.createElement("div");
    this._indoorControl.setAttribute("class", "eegeo-indoor-control");
    this._mountNode.appendChild(this._indoorControl);

    const exitButtonContainer = document.createElement("div");
    exitButtonContainer.setAttribute("id", "eegeo-exit-interior-button-container");
    this._exitButton = document.createElement("button");
    this._exitButton.setAttribute("class", "eegeo-ripple-button");
    this._exitButton.onclick = this._onExitButtonClicked;
    exitButtonContainer.appendChild(this._exitButton);
    this._indoorControl.appendChild(exitButtonContainer);

    if (hasFloors && !this._floorSliderExists) {
      this._floorSliderContainer = document.createElement("div");
      this._floorSliderContainer.setAttribute("class", "eegeo-floor-slider");
      this._indoorControl.appendChild(this._floorSliderContainer);

      this._tracker = document.createElement("div");
      this._tracker.setAttribute("class", "eegeo-floor-slider-tracker");
      this._tracker.style.opacity = 0.5;
      this._floorSliderContainer.appendChild(this._tracker);

      this._numbersContainer = document.createElement("div");
      this._numbersContainer.setAttribute("class", "eegeo-floor-slider-numbers-container");
      this._tracker.appendChild(this._numbersContainer);

      this._barsContainer = document.createElement("div");
      this._barsContainer.setAttribute("class", "eegeo-floor-slider-bars-container");
      this._tracker.appendChild(this._barsContainer);

      this._upIndicatorArrow = document.createElement("div");
      this._upIndicatorArrow.setAttribute("class", "eegeo-floor-slider-up-indicator-arrow");
      this._barsContainer.appendChild(this._upIndicatorArrow);

      this._downIndicatorArrow = document.createElement("div");
      this._downIndicatorArrow.setAttribute("class", "eegeo-floor-slider-down-indicator-arrow");
      this._barsContainer.appendChild(this._downIndicatorArrow);

      this._thumbContainer = document.createElement("div");
      this._thumbContainer.setAttribute("class", "eegeo-floor-slider-thumb-container");
      this._thumbContainer.setAttribute("id", "eegeo-floor-slider-tooltip-hook");
      this._floorSliderContainer.appendChild(this._thumbContainer);

      this._thumb = document.createElement("div");
      this._thumb.setAttribute("class", "eegeo-floor-slider-thumb");
      this._thumb.setAttribute("id", "eegeo-floor-slider-thumb-draggable-handle");
      this._thumb.onmousedown = this._onThumbMouseDown;
      this._thumb.onmouseup = this._onThumbMouseUp;
      this._thumb.innerHTML = "<div class=\"eegeo-floor-slider-thumb-text\">" + this._getTruncatedFloorNumberText(this._map.indoors.getActiveIndoorMap().getFloors()[this._floorIndex].getFloorShortName()) + "</div>";
      this._thumbContainer.appendChild(this._thumb);

      $(this._thumbContainer).draggable({
        axis: "y",
        containment: "parent",
        handle: "#eegeo-floor-slider-thumb-draggable-handle",
        stop: this._onThumbDragEnd,
        drag: this._onThumbDrag
      });

      this._updateSliderHeight();

      this._floorSliderExists = true;

      this._map.indoors.on("indoormapfloorchange", this._onIndoorFloorChange);
      $(window).on("resize", this._updateSliderHeight);
    }
    else {
      const exitButtonHeight = parseInt($(this._exitButton).height());
      this._indoorControl.style.height = exitButtonHeight + "px";
    }
  }

  _destroyView() {
    if (this._indoorControl.parentNode) {
      this._mountNode.removeChild(this._indoorControl);
    }
    this._floorSliderExists = false;
  }

  hideAndDeregister() {
    this._map.indoors.off("indoormapenter", this._onIndoorMapEntered);
    this._map.indoors.off("indoormapexit", this._onIndoorMapExit);
    if(this._map.indoors.isIndoors()){
      this._destroyView();
    }
  }

  _updateSlider() {
    if (this._floorSliderScrollSpeed === 0) {
      this._floorSliderScrollDelta = 0;
    }
    else {
      let baseSliderFloorIndexChanged = false;
      if (this._floorSliderScrollSpeed > 0) {
        if (this._baseSliderFloorIndex + this._maxFloorsToShow < this._floorCount) {
          this._floorSliderScrollDelta += this._floorSliderScrollSpeed;
          if (this._floorSliderScrollDelta >= 1) {
            this._floorSliderScrollDelta -= 1;
            this._baseSliderFloorIndex += 1;
            baseSliderFloorIndexChanged = true;
          }
        }
      }
      else if (this._floorSliderScrollSpeed < 0) {
        if (this._baseSliderFloorIndex > 0) {
          this._floorSliderScrollDelta += this._floorSliderScrollSpeed;
          if (this._floorSliderScrollDelta <= -1) {
            this._floorSliderScrollDelta += 1;
            this._baseSliderFloorIndex -= 1;
            baseSliderFloorIndexChanged = true;
          }
        }
      }

      if (baseSliderFloorIndexChanged) {
        const percent = 1 - (parseInt(this._thumbContainer.style.top) / this._floorSliderHeight);
        this._floorIndex = Math.round(percent * (this._maxFloorsToShow - 1)) + this._baseSliderFloorIndex;
        this._thumb.childNodes[0].innerHTML = this._getTruncatedFloorNumberText(this._map.indoors.getActiveIndoorMap().getFloors()[this._floorIndex].getFloorShortName());
        const tValue = (percent * (this._maxFloorsToShow - 1) + this._baseSliderFloorIndex) / this._floorCount;
        this._map.indoors.setFloorInterpolation(tValue);
        this._updateSliderNumbers();
      }
    }
  }

  _updateSliderNumbers() {
    const numberElements = this._numbersContainer.childNodes;
    for (let i = 0; i < numberElements.length; ++i) {
      numberElements[numberElements.length - i-1].innerHTML = this._map.indoors.getActiveIndoorMap().getFloors()[i + this._baseSliderFloorIndex].getFloorShortName();
      numberElements[numberElements.length - i-1].style.visibility = this._floorIndex === i + this._baseSliderFloorIndex ? "hidden" : "inherit";
    }
    this._updateIndicatorArrowOpacity();
  }

  _updateIndicatorArrowOpacity() {
    this._upIndicatorArrow.style.opacity = this._baseSliderFloorIndex + this._maxFloorsToShow < this._floorCount ? 1 : 0;
    this._downIndicatorArrow.style.opacity = this._baseSliderFloorIndex > 0 ? 1 : 0;
  }

  _updateSliderHeight() {
    const exitButtonHeight = parseInt($(this._exitButton).height());

    const floorSliderMarginTop = parseInt($(this._floorSliderContainer).css("marginTop"));
    const halfElevatorButtonHeight = $(this._thumbContainer).height() * 0.5;
    const elevatorButtonPaddingtop = parseInt($(this._thumbContainer).css("paddingTop"));
    const minFloorIntervalHeight = 30;
    const maxFloorIntervalHeight = 60;
    let controlHeight = Math.min($(this._mountNode).height(), maxFloorIntervalHeight * (this._floorCount - 1) + exitButtonHeight + floorSliderMarginTop + halfElevatorButtonHeight);
    controlHeight = Math.max(controlHeight, minFloorIntervalHeight + exitButtonHeight + floorSliderMarginTop + halfElevatorButtonHeight);

    this._floorSliderHeight = controlHeight - exitButtonHeight - floorSliderMarginTop - halfElevatorButtonHeight;
    this._floorSliderContainer.style.height = this._floorSliderHeight + halfElevatorButtonHeight + elevatorButtonPaddingtop + "px";
    this._tracker.style.height = this._floorSliderHeight + "px";

    this._maxFloorsToShow = parseInt(this._floorSliderHeight / minFloorIntervalHeight) + 1;
    this._showAllFloors = this._maxFloorsToShow > this._floorCount;
    this._updateBaseSliderFloorIndex();

    const numFloorsShowing = this._showAllFloors ? this._floorCount : this._maxFloorsToShow;
    const numberHeight = this._floorSliderHeight / (numFloorsShowing - 1);

    while (this._barsContainer.hasChildNodes()) {
      this._barsContainer.removeChild(this._barsContainer.lastChild);
    }
    this._barsContainer.appendChild(this._upIndicatorArrow);
    this._barsContainer.appendChild(this._downIndicatorArrow);
    for (let i = numFloorsShowing - 1; i > -1; --i) {
      const barContainer = document.createElement("div");
      barContainer.setAttribute("class", "eegeo-floor-slider-bar-container");
      if (i > 0) {
        barContainer.style.height = numberHeight + "px";
      }
      this._barsContainer.appendChild(barContainer);
      const barElement = document.createElement("div");
      barElement.setAttribute("class", "eegeo-floor-slider-bar");
      barContainer.appendChild(barElement);
    }

    while (this._numbersContainer.hasChildNodes()) {
      this._numbersContainer.removeChild(this._numbersContainer.lastChild);
    }
    for (let i = numFloorsShowing - 1; i > -1; --i) {
      const numberElement = document.createElement("div");
      if (i > 0) {
        numberElement.style.height = numberHeight + "px";
      }
      numberElement.style.lineHeight = 16 + "px";
      numberElement.innerHTML = this._map.indoors.getActiveIndoorMap().getFloors()[i + this._baseSliderFloorIndex].getFloorShortName();
      numberElement.style.visibility = this._floorIndex === i + this._baseSliderFloorIndex ? "hidden" : "visible";
      this._numbersContainer.appendChild(numberElement);
    }

    this._updateSliderNumbers();
    this._setThumbPosition();

    this._indoorControl.style.height = controlHeight + "px";
  }
}

const wrldIndoorControl = (id, map, options) => {
  return new WrldIndoorControl(id, map, options);
};

export {
  WrldIndoorControl,
  wrldIndoorControl,
  WrldIndoorControl as EegeoIndoorControl,
  wrldIndoorControl as eegeoIndoorControl
};
